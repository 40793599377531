import Footer from "./components/Footer";
import Navbar from "./components/Navbar/index";
import Home from "./pages/Home/index";
import Plans from "./components/Plans/Plans";
import Announcements from "./components/Announcements/index";
import announcementBG from "./assets/announcementBG.png";

function App() {
  return (
    <div>
      <Navbar />
      <Home />
      <div
        style={{ backgroundImage: `url(${announcementBG})` }}
        className="flex flex-row justify-center items-start gap-4 bg-slate-100"
      >
        <Announcements />
        <Plans />
      </div>

      <Footer />
    </div>
  );
}

export default App;
