import React, { useEffect, useState } from "react";
import { AiOutlineLeft, AiOutlineRight } from "react-icons/ai";

const Plans = () => {
  
  const slides = [
    {
      text: "İZMİR İLİ, BALÇOVA İLÇESİ, KORUTÜRK VE TELEFERİK MAHALLELERİNDE YER ALAN, İZMİR İNCİRALTI TURİZM MERKEZİ TEVSİİ BALÇOVA KAPLICALARI KESİMİ 1/1000 ÖLÇEKLİ 1. ETAP UYGULAMA İMAR PLANI PLAN NOTU DEĞİŞİKLİĞİ VE İLAVESİ",
    },
    {
      text: "İZMİR İLİ, BALÇOVA İLÇESİ, KORUTÜRK MAHALLESİNDE YER ALAN, İZMİR İNCİRALTI TURİZM MERKEZİ TEVSİİ BALÇOVA KAPLICALARI KESİMİ 1/1000 ÖLÇEKLİ 2. ETAP UYGULAMA İMAR PLANI PLAN NOTU DEĞİŞİKLİĞİ VE İLAVESİ",
    },
    {
      text: "İZMİR İLİ, BALÇOVA İLÇESİ, KORUTÜRK MAHALLESİNDE YER ALAN, İZMİR İNCİRALTI TURİZM MERKEZİ TEVSİİ BALÇOVA KAPLICALARI KESİMİ 1/1000 ÖLÇEKLİ 2. ETAP UYGULAMA İMAR PLANI PLAN NOTU DEĞİŞİKLİĞİ VE İLAVESİ",
    }
  ];
  
  return (
    <div className="text-white p-5">
      <div className="flex flex-col gap-3 justify-center items-center">
        <div className="text-2xl mb-4 text-[#00629C]">Askıda İmar Planları</div>
        {slides.map((slide, index) => (
          <div key={index} className="border-2 p-4 flex items-center justify-center rounded-md w-[600px] min-h-[180px] bg-slate-50">
            <div className="text-center text-black w-full px-8">
              {slide.text}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Plans;
