import React, { useEffect, useState } from "react";
import { AiOutlineLeft, AiOutlineRight } from "react-icons/ai";

const Announcements = () => {

  const slides = [
    {
      url: "https://www.balcova.bel.tr/images/duyurular/2023124102937861.jpg",
      text: "Yerel Eşitlik Eylem Planı",
    },
    {
      url: "https://www.balcova.bel.tr/images/duyurular/20167191612864.jpg",
      text: "İkinci resim açıklaması",
    },
    {
      url: "https://www.balcova.bel.tr/images/duyurular/2023124102937861.jpg",
      text: "Üçüncü resim açıklaması",
    },
  ];
 
  return (
    <div className="text-white p-5">
      <div className="flex flex-col gap-3 justify-center items-center">
        <div className="text-2xl mb-4 text-[#00629C]">Duyurular</div>
        {slides.map((slide, index) => (
          <div key={index} className="border-2 p-4 flex flex-col items-center justify-around rounded-md w-[600px] min-h-[180px] bg-slate-50">
            <img
              className="w-24 mb-4"
              src={slide.url}
              alt="Duyuru Resmi"
            />
            <div className="text-center text-black">
              {slide.text}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
export default Announcements;
